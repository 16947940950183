<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/screen.png')"
          class="my-3"
          contain
          height="80"
          style="filter: grayscale(1);"
        />
      </v-col>

      </v-row>
    <v-divider class="mt-4"></v-divider><br />
    Barclays announced its Half Year Results on Thursday 1 August 2024.
    Results webcast and conference call replay hosted by C.S. Venkatakrishnan, Group Chief Executive and Anna Cross, Group Finance Director
    <br /><br /><v-divider class="mb-4"></v-divider>

    
  </v-container>
</template>


<script>

export default ({
  name: 'ReportView'

});

</script>
