<template>
  
  <v-app>
    <!-- MENU START -->
    <v-app-bar prominent>
        <v-app-bar-nav-icon
          variant="text"
          @click.stop="drawer = !drawer"
        >
        </v-app-bar-nav-icon>
      <v-app-bar-title>ShopList</v-app-bar-title>
      <v-btn @click="toggleTheme" stacked>
          <v-icon :icon="theme.global.name.value === 'dark' ? 'mdi-weather-sunny' : 'mdi-weather-night'"></v-icon>
      </v-btn>
      <v-btn v-if="deferredPrompt" @click="install" stacked>
        <v-icon>mdi-download</v-icon>
      </v-btn>
      </v-app-bar>
      <v-navigation-drawer
        v-model="drawer"
        :location="$vuetify.display.mobile ? 'bottom' : undefined"
        temporary
      >
        <v-list>
          <v-list-item
            prepend-icon="mdi-calculator"
            title="Vai al Report"
            to="/report"
          ></v-list-item>
        </v-list>
      </v-navigation-drawer>
    <!-- MENU END-->      
    
    <v-dialog
      v-model="dialog"
      width="auto"
      v-if="pushpermission"
    >
      <v-card
        max-width="400"
        prepend-icon="mdi-bell-outline"
        text="L'applicazione chiede di abilitare le notifiche push così da essere informato sulle novità e sulle cose che vengono fatte."
        title="Abilita le Notifiche Push"
      >
        <template v-slot:actions>
          <v-btn
            text="Chiudi"
            @click="dialog = false"
          ></v-btn>
          <v-btn
            text="Ok"
            @click="[push(), dialog = false]"
          ></v-btn>
        </template>
      </v-card>
    </v-dialog>

    <v-main>
      <router-view/>
    </v-main>
    
  <v-bottom-navigation height="80" :elevation="8" grow>
    <v-btn to="/" value="Lista" :active="false">
      <v-icon>mdi-cart-outline</v-icon>
      
    </v-btn>
    
    <v-btn to="/about" value="About" :active="false">
      <v-icon>mdi-magnify</v-icon>
      
    </v-btn>

    <v-btn to="/report" value="Report" :active="false">
      <v-icon>mdi-calculator</v-icon>
      
    </v-btn>
  </v-bottom-navigation>
 


  </v-app>


</template>

<script>
export default {
  name: 'App',

  data: () => ({
    deferredPrompt: null,
    pushpermission: null,
    dialog: true,
    drawer: null,
  }),

  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
     window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
    window.addEventListener('DOMContentLoaded', e => {
      e.preventDefault();
      if (Notification.permission === 'granted' || Notification.permission === 'denied') {
        this.pushpermission = null;
      }
      else {
        this.pushpermission = e;
      }
    });
    
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }
  }

}


</script>

<script setup>
import { useTheme } from 'vuetify'
import { initializeApp } from "firebase/app";    
import { getMessaging, getToken, onMessage } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyD31nz1mm_IgOE0uvTph_LWfh5b0aW0CeU",
  authDomain: "p-prj-em.firebaseapp.com",
  projectId: "p-prj-em",
  storageBucket: "p-prj-em.appspot.com",
  messagingSenderId: "792743293324",
  appId: "1:792743293324:web:f422342301fa30fab266a9"
};

const app = initializeApp(firebaseConfig);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging();  
onMessage(messaging, (payload) => {
  //console.log('Message received. ', payload);
  // ...
  console.log(
      '[firebase-messaging-sw.js] Received background message ',
      payload
    );
    // ...
});



const theme = useTheme();
function toggleTheme () {
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark';
}

// Example: wrapping permission request call inside a button click
async function push() {
  let permission = await Notification.requestPermission();
  if (permission === "granted") {
    console.log("Notification permission granted. Requesting for token.");
      
      
       getToken(messaging, { vapidKey: 'BPiN7iki7SZFPlDSBcgQJaWiI4S9AYX9oqh6S6Bvx1HmvcgsGMEyEQSNfUlrU9q5RV9B5keDGGfgb7wnf6bST4c' }).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          console.log("Token is:",currentToken);
          
          // ...
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        push();  // if there is an error on retrieve token recall the function to get the token // pay attention !!!
        // ...
      });
      
    // do something with the FCM token
  } else {
    console.log("Notification permission denied");
    // Handle denied permission
  }
  
}

function sendPNotfromApp() {

}


</script>


