<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="80"
        />
      </v-col>

</v-row>
<v-text-field
      v-model="newTask"
      label="Cosa mettiamo nella lista?"
      variant="solo"
      @keydown.enter="addTask"
    >
      <template v-slot:append-inner>
        <v-fade-transition>
          <v-btn
            v-show="newTask"
            icon="mdi-plus-circle"
            variant="text"
            @click="addTask"
            
          ></v-btn>
        </v-fade-transition>
      </template>
    </v-text-field>

    <h2 class="text-h4 text-secondary ps-2">
      Tot. Articoli:&nbsp;
      <v-fade-transition leave-absolute>
        <span :key="`tasks-${tasks.length}`"> {{ tasks.length }} </span>
      </v-fade-transition>
    </h2>

    <v-divider class="mt-4"></v-divider>

    <v-row align="center" class="my-1">
      <strong class="mx-4 text-info-darken-2">
        Restano: {{ remainingTasks }}
      </strong>

      <v-divider vertical></v-divider>

      <strong class="mx-4 text-success-darken-2">
        Completati: {{ completedTasks }}
      </strong>

      <v-spacer></v-spacer>

      <v-progress-circular
        v-model="progress"
        class="me-3"
      ></v-progress-circular>
    </v-row>

    <v-divider class="mb-4"></v-divider>

    <v-card v-if="tasks.length > 0">
      <v-slide-y-transition class="py-0" tag="v-list" group >
        <template v-for="(task, i) in tasks" 
                  :key="`${i}-${task.text}`" 
                  
        >
          <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>

          <v-list-item>
            
            <template v-slot:prepend>
              <v-checkbox-btn v-model="task.done" color="grey" @click="updateTask(task.id, task.text, task.done)"></v-checkbox-btn>
            </template>
            <v-list-item-title>
              <span :class="task.done ? 'done' : 'undone'">{{ task.text }}</span>
            </v-list-item-title>
            <template v-slot:append>
              <v-expand-x-transition>
                <v-icon v-if="task.done" color="secondary"> mdi-check </v-icon>
              </v-expand-x-transition>
              <v-expand-x-transition>
                <v-icon @click="deleteTask(task.id, `${i}`)" color="red" style="margin-left: 10px;"> mdi-close </v-icon>
              </v-expand-x-transition>
            </template>
          </v-list-item>
        </template>
      </v-slide-y-transition>
    </v-card>
    
  </v-container>
</template>

<style>
.done {
  cursor: pointer;
  text-decoration: line-through;
  opacity: .25;
}
.undone {
  cursor: pointer;
  opacity: 1;
}
</style>

<script> 
const DB_NAME = 'tasks-db';
const DB_VERSION = 1;

export default {
    data: () => ({
      tasks: [],
      newTask: null
    }),
    
    async created() {
      this.db = await this.getDb();
      this.tasks = await this.getTasksFromDb();
      this.ready = true;
    },

    computed: {
      completedTasks() {
        return this.tasks.filter(task => task.done).length
      },
      progress() {
        return (this.completedTasks / this.tasks.length) * 100
      },
      remainingTasks() {
        return this.tasks.length - this.completedTasks
      },
    },

    methods: {
      async addTask() {
        this.addDisabled = true;
        let task = {
          text: this.newTask,
          done: false
        };
        //console.log('about to add '+JSON.stringify(cat));
        await this.addTaskToDb(task);
        this.tasks = await this.getTasksFromDb();
        this.addDisabled = false;     
        this.newTask = null; 
      },

      async deleteTask(id, i) {
        await this.deleteTaskFromDb(id);
        //this.tasks = await this.getTasksFromDb();   // avoid to refresh data...
        this.tasks.splice(i, 1) // remove it from array
      },

      async updateTask(id, txt, state) {
        this.addDisabled = true;
        let task = {
          text: txt,
          done: state == false ? true : false,
          id: id
        };
        await this.updateTaskToDb(task);
        //this.tasks = await this.getTasksFromDb();
        this.addDisabled = false;     
        
      },
      
      async addTaskToDb(task) {
        return new Promise((resolve, reject) => {

        let trans = this.db.transaction(['tasks'],'readwrite');
        trans.oncomplete = e => {
          resolve();
        };

        let store = trans.objectStore('tasks');
        store.add(task);

      });
    },
    async deleteTaskFromDb(id) {
      return new Promise((resolve, reject) => {
        let trans = this.db.transaction(['tasks'],'readwrite');
        trans.oncomplete = e => {
          resolve();
        };

        let store = trans.objectStore('tasks');
        store.delete(id);
      });
    },
    async updateTaskToDb(task) {
      return new Promise((resolve, reject) => {
        let trans = this.db.transaction(['tasks'],'readwrite');
        trans.oncomplete = e => {
          resolve();
        };
        let store = trans.objectStore('tasks');
        store.put(task);
      });
    },
     getTasksFromDb() {
      
      return new Promise((resolve, reject) => {

        let trans = this.db.transaction(['tasks'],'readonly');
        trans.oncomplete = e => {
          resolve(this.tasks);
        };
        
        let store = trans.objectStore('tasks');
        this.tasks = [];
        
        store.openCursor().onsuccess = e => {
          let cursor = e.target.result;
          if (cursor) {
            this.tasks.push(cursor.value);
            cursor.continue();
          }
          
        };
      });
      
    },
    async getDb() {
      return new Promise((resolve, reject) => {

        let request = window.indexedDB.open(DB_NAME, DB_VERSION);
        
        request.onerror = e => {
          console.log('Error opening db', e);
          reject('Error');
        };
  
        request.onsuccess = e => {
          resolve(e.target.result);
        };
        
        request.onupgradeneeded = e => {
          console.log('onupgradeneeded');
          let db = e.target.result;
          let objectStore = db.createObjectStore("tasks", { autoIncrement: true, keyPath:'id' });
        };
      });
    }
    },
  }

</script>
